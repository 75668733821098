import React, { useEffect, useState, useCallback } from  'react';

import { isAuthenticated, setLogout } from '@/services/provider/auth';
import { Navigate } from 'react-router-dom';

import { api, URL_VALIDATE_TOKEN } from '@/services/provider/api';

export const RouterPrivate = (props) => {

    const [isAuth, setIsAuth] = useState(<></>);

    const validateToken = useCallback(async () => {
        const tokenvalid = await api.get(URL_VALIDATE_TOKEN, null, true);
        if(tokenvalid?.data){
            setIsAuth(<>{props?.children}</>);
        }else{
            setLogout();
            setIsAuth(<Navigate replace to="../login" />);
        }

    },[setIsAuth, props])

    useEffect(()=>{
        validateToken();
    }, [validateToken])
        
    if(!isAuthenticated())
        return <Navigate replace to="../login" />
    else
        return isAuth

}