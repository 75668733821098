import React, { useState, useEffect, useCallback, useMemo} from "react";

import { Divider } from 'primereact/divider';
import { TabMenu } from 'primereact/tabmenu';
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';

import { useNavigate } from "react-router-dom";
import { api, URL_FEEDBACK_ALLFEEDBACKS, URL_FEEDBACK_MYFEEDBACKS } from "@/services/provider/api";


export const PageFeedback = () => {

    const navigate = useNavigate();

    const [activeIndex, setActiveIndex] = useState(0);
    const [data, setData] = useState();

    const items = useMemo(()=>[
        {label:'Meus feedbacks', icon:'pi pi-check', baseurl: null},
        {label:'Aberto', baseurl:`open`},
        {label:'Fechado', baseurl:`close`},
        {label:'Todos', baseurl:null}
    ], [])

    const getData = useCallback(async (idx)=>{

        if(idx === 0){
            const result = await api.get(URL_FEEDBACK_MYFEEDBACKS, null, true);
            setData(result.data);
        }else{
            const result = await api.get(`${URL_FEEDBACK_ALLFEEDBACKS}${items[idx]?.baseurl ? `/${items[idx]?.baseurl}` : '' }`, null, true);
            setData(result.data);
        }

    }, [setData, items])


    useEffect(()=>{
        setData(null);
        getData(activeIndex)
    }, [activeIndex, getData, setData]);

    return (
        <div className="page-feedback">
             <Divider type="dashed" >
                <div className="inline-flex align-items-center">
                    <i className="pi pi-star mr-2"></i>
                    <b>Feedback</b>
                </div>
            </Divider>
            <div className="grid mt-2 mb-2">
                <div className="col-12">
                    <Toolbar left={()=>{
                            return <>
                            </>
                        }} 
                        right={()=>{
                            return <>
                                <Button onClick={()=>navigate('/user/feedback/database')} label='Criar feedback' className='p-button-sm p-button-primary mr-2' icon="pi pi-list" />
                                <Button onClick={()=>navigate('/user/feedback/questions')} label='Criar questões' className='p-button-sm p-button-info mr-2' icon="pi pi-folder" />
                            </>
                        }} />
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>
                </div>
            </div>
            <div className="grid mt-4">
                <div className="col-12">
                    {
                        activeIndex === 0 ?
                            <DataTable size="small" paginator={data?.length > 10} rows={10} value={data} emptyMessage="Não há registros" >
                                <Column sortable field="name" header="Nome" />
                                <Column body={(row)=>{
                                    return <Tag style={{width:'100%'}} value={ (~~row?.answers?.aswers_user_total === ~~row?.answers?.total_questions && ~~row?.answers?.aswers_user_total > 0 ? 'Finalizada' : 'Aberta')} severity={ ~~row?.answers?.aswers_user_total === ~~row?.answers?.total_questions && ~~row?.answers?.aswers_user_total > 0 ? 'success' : 'warning'   }  />;
                                }} sortable style={{width:'100px'}} header="Status" />
                                <Column style={{width:'40px'}} body={(row)=>{
                                    return <Button onClick={()=>navigate(`/user/feedback/${row.id}/answer`)} icon="pi pi-pencil" className="p-button-text p-button-sm p-button-primary" />
                                }} header="" />
                            </DataTable>
                        :
                            <DataTable size="small" paginator={data?.length > 10} rows={10} value={data || []} emptyMessage="Não há registros" >
                                <Column sortable field="name" header="Nome" />
                                <Column body={(row)=>{
                                    const date = new Date(row.create);
                                    return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`
                                }} sortable field="create" header="Início" />
                                <Column body={(row)=>{
                                    const date = new Date(row.end);
                                    return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`
                                }} sortable field="end" header="Fim" />
                                <Column body={(row)=>{
                                    return <Button onClick={()=>navigate(`/user/feedback/${row.id}`)} icon="pi pi-eye" className="p-button-text p-button-sm p-button-primary" />
                                }} header="" />
                            </DataTable>
                    }
                </div>
            </div>
        </div>
    )
}