
import { localeData, secondsToTime, timeToSeconds } from '@/services/utils';
import { Button, Column, DataTable } from 'primereact';
import { useCallback, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { api, URL_REGISTER_JOURNEY_BY_MONTH } from '@/services/provider/api';
import { useAPP } from '@/services/provider/app';

export const JourneyMonth = (props) => {


    const { user } = useAPP();

    const [list, setList] = useState([]);
    const [showNote, setShowNote] = useState(false);
    const [noteData, setNoteData] = useState();

    const dateReference = new Date();
    dateReference.setDate(1);

    const month = dateReference.getMonth();

    const reference = [];
    let day = 1;
    do{
        let insert = {date: new Date(`${dateReference.getFullYear()}-${dateReference.getMonth()+1}-${day < 10 ? '0'+day : day } 00:00:00`), day: dateReference.getDate(), weekNumber: dateReference.getDay() , week: localeData.br.dayNames[dateReference.getDay()]};
        insert.start = '-';
        insert.pausestart = '-';
        insert.pauseend = '-';
        insert.end = '-';
        insert.total = '-';
        insert.erros = true;
        insert.errosTotal = 0;
        insert.startType = '';
        insert.endType = '';

        reference.push(insert);
        day++;
        dateReference.setDate(day);
    }while(dateReference.getMonth() === month);



    const getData = useCallback(async () => {

        const dateTemp =  new Date();

        const data =  await api.get(`${URL_REGISTER_JOURNEY_BY_MONTH}/${props?.date || `${dateTemp.getFullYear()}-${dateTemp.getMonth() +1}-01` }/${props?.user_id || user.id}`, null, true);

        

        const temp = reference.map(item => {
            const _dateKey = (`${item.date.getFullYear()}-${item.date.getMonth() +1}-${item.day < 10 ? `0${item.day}` : item.day}`).replace(/-/gi, '_');
            
            const _dateRegister = data.data[_dateKey];

            const _admission = new Date(`${user?.admission} 00:00:00`);

            if(_admission){
                if(item.date.getTime() < _admission.getTime()){
                    item.erros = false;
                    return item;
                }

            }

            if(item.date.getTime() > (new Date()).getTime()){
                item.erros = false;
                return item;
            }

            
            if( item.weekNumber > 0 && item.weekNumber < 6){
                item.pausestart = user.lunch_start;
                item.pauseend = user.lunch_end;
                item.start =_dateRegister?.register?.at(0)?.time || '-'
                item.startNote =_dateRegister?.register?.at(0)?.note
                item.startType = _dateRegister?.register?.at(0)?.type || '-'

                if(_dateRegister?.register?.length > 1){
                    item.end = _dateRegister?.register?.at(-1)?.time || '-'
                    item.endNote = _dateRegister?.register?.at(-1)?.note
                    item.endType = _dateRegister?.register?.at(-1)?.type || '-'
                }

                if(!_dateRegister){
                    item.errosTotal = 2;
                }else{
                    
                    if(item.start === item.end){
                        item.errosTotal = 1;
                        item.start = '-'
                        item.end = '-'
                    }else if(~~_dateRegister?.register?.length % 2 !== 0){
                        item.errosTotal = ~~_dateRegister?.register?.length;
                    }else{
                        item.erros = false;
                    }
                }

            }else{
                item.erros = false;
                item.errosTotal = 1;
            }


            if(item.date.toLocaleDateString() === (new Date()).toLocaleDateString()){
                item.erros = false;
            }

            if(!item.erros && item.errosTotal === 0){
                const _jf = timeToSeconds(item.end) - timeToSeconds(item.start);
                const _af = timeToSeconds(item.pauseend) - timeToSeconds(item.pausestart);
                const _tot = _jf - _af;

                item.total = secondsToTime(_tot);
            }

            return item;

        })
    

        setList(reference);
    }, [reference])

    useEffect(()=>{
        getData();
    }, [])

    return (
        <div className="component-journey-month">
            <h2>

                Resumo do mês 
            </h2>
            <DataTable 
                value={list} 
                emptyMessage="Não foram encontrados registros"
                stripedRows
                style={{fontSize:'12px'}}
                
            >
                <Column style={{width:'45px'}} field='day' header="Dia" />
                <Column style={{width:'80px'}} field='week' header="Semana" />
                <Column style={{width:'80px'}} body={(row)=> {
                    return !(/-/gi).test(row?.start) && !(/holiday/gi).test(row?.startType) && row.startNote?.length > 0 ? <Button onClick={()=>{
                        setNoteData(row.startNote);
                        setShowNote(true);
                    }} label={row.start} icon={row?.startNote ? 'pi pi-eye' : null} className='p-button-sm p-button-text' iconPos='right' /> : row.start
                }} field='start' header="Entrada" />
                <Column style={{width:'80px'}} field='pausestart' header="Almoço" />
                <Column style={{width:'80px'}} field='pauseend' header="Almoço" />
                <Column style={{width:'80px'}} body={(row)=> {
                    return !(/-/gi).test(row?.end) && !(/holiday/gi).test(row?.startType) && row.endNote?.length > 0 ? <Button onClick={()=>{
                        setNoteData(row.endNote);
                        setShowNote(true);
                    }} label={row.end} icon={row?.endNote ? 'pi pi-eye' : null} className='p-button-sm p-button-text' iconPos='right' /> : row.end
                }} field='end' header="Sáida" />
                <Column style={{width:'80px'}} field='total' header="Total" />
                <Column style={{textAlign:'right'}} body={(row)=>{
                    return row.erros ? <Button icon='pi pi-exclamation-triangle' className='p-button-text p-button-sm p-button-danger' /> : ((/holiday/gi).test(row?.startType) || (/holiday/gi).test(row?.endType) ? row?.startNote || row?.endNote  : '')
                }} />
            </DataTable>
            <Dialog draggable={false} header={`Nota`} visible={showNote} style={{ minWidth: '50%', maxWidth:'1000px' }} footer={()=><></>} onHide={() => setShowNote(false)}>
                {noteData}
            </Dialog>
        </div>
    )
}