import React, { useState, useEffect, useCallback } from 'react';

import { Chart } from 'primereact/chart';
import * as ChartB from 'chart.js/auto';

import { localeData } from '@/services/utils';
import { useAPP } from '@/services/provider/app';
import { api, URL_REGISTER_JOURNEY_BY_YEAR } from '@/services/provider/api';


export const JourneyYear = (props) => {

    const { user } = useAPP();

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    const data = {
        labels: localeData.br.monthNames,
        datasets: [
            {
                type: 'bar',
                backgroundColor: '#d0e1fd',
                label: 'Hora Trabalhada',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
                type: 'bar',
                label: 'Hora Extra',
                backgroundColor: '#85b2f9',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
                type: 'bar',
                label: 'Hora Pendentes',
                backgroundColor: '#ff8780',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
        ]
    };
    const options = {
        maintainAspectRatio: false,
        aspectRatio: 1,
        animations: null,
        plugins: {
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function(context) {
                       // return  context.raw;
                    }
                }
            },
            legend: {
                labels: {
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    
                },
                grid: {
                    display: false
                }
            },
            y: {
                stacked: true,
                display: false,
                ticks: {
                },
                grid: {
                    display: false
                }
            }
        }
    };

    const getData = useCallback(async () => {
        const data = await api.get(`${URL_REGISTER_JOURNEY_BY_YEAR}/${props?.date || (new Date()).toISOString().substring(0, 10) }/${props?.user_id || user.id}`, null, true)
        console.log(data?.data);
    }, [user, props])

    useEffect(()=>{
        getData();
        setChartData(data);
        setChartOptions(options);
    }, [])

    return (
        <div style={{minHeight:'300px'}} className="component-journey-year">
            <h2 style={{color:'var(--blue-700)'}}>
                Visão anual - { new Date().getFullYear()}
            </h2>
            <Chart key={new Date().getTime()} style={{with:'100%'}} type="bar" data={chartData} options={chartOptions} />
        </div>
    )
}