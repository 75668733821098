import { useCallback, useEffect, useRef, useState } from "react";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';

import { URL_USER_BANK_DATA, api } from "@/services/provider/api"
import { secondsToTime, timeToSeconds } from "@/services/utils";

export const PageBank = ({user = null}) => {

    const [bank, setBank] = useState();
    const sendGet = useRef(false);
    const sendGetUnique = useRef(false);
    const dbBase = useRef([]);

    const getDataUnique = useCallback(async()=>{
        
        bank.map(item => {
            const dt = api.get(`${URL_USER_BANK_DATA}/${user}/${item.date}`, null, true);
            dt.then((rs)=> {
                dbBase.current = dbBase.current.map(item => {

                    console.log(item);

                    if(item.date === rs.data[0].date){
                        item = {...item, ...rs.data[0]}
                    }

                    return item;
                })
                
                setBank(dbBase.current);
            })
        })

    },[bank])

    const getData = useCallback(async () => {
        let data = await api.get(`${URL_USER_BANK_DATA}/${user}?keys=true`, null, true);
        const currentDateTemp = new Date().toISOString().substring(0, 7);
        data.data = data.data.filter(item => item.date !== currentDateTemp);
       
        dbBase.current = data.data;
        setBank(data.data);
    },[])


    useEffect(()=>{
        if(bank?.length > 0 && !sendGetUnique.current){
            sendGetUnique.current = true;
            getDataUnique();
        }
    },[bank]);

    useEffect(() => {
        if(!sendGet.current){
            sendGet.current = true;
            getData();
        }
    }, [])

    return (
        <div className="page-bank">
            <Divider type="dashed" >
                <div className="inline-flex align-items-center">
                    <i className="pi pi-calendar  mr-2"></i>
                    <b>Banco de horas</b>
                </div>
            </Divider>
            <div className="grid mt-2 mb-2">
                <div className="col-6">
                    <div className="card" style={{'--bg':'var(--blue-200)', '--text':'var(--blue-800)'}}>
                        <div className="title">Total geral</div>
                        <div className="icon">
                            <i className="pi pi-clock" />
                        </div>
                        <div className="content">
                            {
                                bank ?
                                   secondsToTime(bank.reduce((a, n) =>{ 
                                        console.log(secondsToTime(a));
                                        return a + (~~n.total);
                                    }, 0)
                                   )
                                :
                                    '-'
                            }
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card" style={{'--bg':'var(--red-200)', '--text':'var(--red-800)'}}>
                        <div className="title">Erros</div>
                        <div className="icon">
                            <i className="pi pi-times" />
                        </div>
                        <div className="content">
                            {
                                bank ?
                                    bank?.reduce((a, n) => a + ~~n.erros, 0)
                                :
                                    '-'
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <DataTable
                        value={bank || []}
                        size="small"
                        stripedRows
                        loading={!bank}
                    >
                        <Column field="date" header='Periodo' body={(row) => (row.date.split('-').reverse()).join('/')} />
                        {/* <Column body={(row)=> row?.work?.toString() ? secondsToTime(row?.work?.toString()).toString() : <i className='pi pi-spin pi-spinner' /> } field="work" header='Trabalhado'  /> */}
                        <Column body={(row)=> row?.positive?.toString() ? secondsToTime(row?.positive?.toString()).toString() : <i className='pi pi-spin pi-spinner' /> } field="positive" header='Positivo'  />
                        <Column body={(row)=> row?.negative?.toString() ? secondsToTime(row?.negative?.toString()).toString() : <i className='pi pi-spin pi-spinner' /> }  field="negative" header='Negativo'  />
                        <Column body={(row)=> row?.total?.toString() ? secondsToTime(row?.total?.toString()).toString() : <i className='pi pi-spin pi-spinner' /> }  field="total" header='Total'  />
                        <Column field="erros" header='Erros'  />
                    </DataTable>
                </div>
            </div>
        </div>

    )
}
