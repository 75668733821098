import React, { useState, useEffect, useCallback, useRef } from "react";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { confirmDialog } from 'primereact/confirmdialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { localeData } from '@/services/utils';
import { addLocale } from 'primereact/api';
import { Button } from "primereact/button";

import { api, URL_REGISTER_BY_DAY, URL_REGISTER, URL_REGISTER_LIST_ERROS, URL_USER_DELETE_CHECK } from '@/services/provider/api';
import { useAPP } from "@/services/provider/app";

import { InputTextarea } from 'primereact/inputtextarea';
 

export const CheckTime = ({_date, userId}) => {

    addLocale('pt-br', localeData.br);

    const { user } = useAPP();

    const [currentDate, setCurrentDate] = useState(_date || new Date());
    const [currentTime, setCurrentTime] = useState(new Date());
    const [currentPendence, setCurrentPendence] = useState()
    const [note, setNote] = useState('');
    const [dayInfo, setDayInfo] = useState();
    const [pendences, setPendences] = useState();

    const [isLoad, setIsLoad] = useState(false);

    const toast = useRef(null);
    const hourEntrie = useRef(new Date());

    const getData = useCallback(async ()=> {
        const data = await api.get(`${URL_REGISTER_BY_DAY}/${currentDate.toISOString().substr(0, 10) }/${(userId || user.id)}`, null, true);
        const dataErros = await api.get(`${URL_REGISTER_LIST_ERROS}/${(userId || user.id)}`, null, true);
        const errosList = dataErros.data?.map(item =>{ 
            const dateTemp = item.date.toString().split('/');
            const _temp = new Date(`${dateTemp[2]}-${dateTemp[1]}-${dateTemp[0]} 00:00:00`);
            return ({label: _temp.toLocaleDateString(), value: _temp})
        });
        
        setPendences(errosList);
        setDayInfo(data.data);
        setIsLoad(false);
    }, [currentDate, user])

    const selectPendence = (_data) => {

        if(_data){
            setCurrentPendence(_data);
            setCurrentDate(_data);
        }else{
            setCurrentPendence(null);
            setCurrentDate(new Date());
        }
    }

    const saveRegister = useCallback(async () => {

        const now = new Date();

        const isReview = hourEntrie.current.toLocaleDateString() !== currentDate.toLocaleDateString() || hourEntrie.current.toLocaleTimeString() !== currentTime.toLocaleTimeString();


        if(isReview){
            if(note.length === 0){
                toast.current.show({severity:'error', summary: 'Erro', detail:'Campo justificativa deve ser preenchido', life: 3000});
                return;
            }
        }

        const acceptFunc = async () => {
            const data = {
                datetime: `${currentDate.toISOString().substr(0, 10)} ${currentTime.toLocaleTimeString()}`,
                note: note,
                id: (userId || user.id),
                review: isReview ? 1 : 0
            };

            setIsLoad(true);

            const result = await api.post(URL_REGISTER, data , false);

            if(~~result.data.status === 200){
                toast.current.show({severity:'success', summary: 'Sucesso', detail: result.data.message, life: 3000});
            }else{
                toast.current.show({severity:'error', summary: 'Erro', detail:result.data.message, life: 3000});
            }


            getData();
        }

        const rejectFunc = () => {}

        confirmDialog({
            message: `Deseja efetuar o lançamento ${currentDate.toLocaleDateString()} ${currentTime.toLocaleTimeString()}?`,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel:"Sim",
            rejectLabel:"Não",
            accept: () => acceptFunc(),
            reject: () => rejectFunc()
        });

    }, [note, currentDate, currentTime]);


    const deleteCheck = useCallback(async (_data) => {

        const acceptFunc = async () => {
            
            setIsLoad(true);

            const result = await api.delete(`${URL_USER_DELETE_CHECK}/${_data.id}`, null , true);

            toast.current.show({severity:'success', summary: 'Sucesso', detail: result?.data, life: 3000});

            // if(~~result.data.status === 200){
            // }else{
            //     toast.current.show({severity:'error', summary: 'Erro', detail:result.data.message, life: 3000});
            // }


            getData();
        }

        const rejectFunc = () => {}

        confirmDialog({
            message: `Deseja excluir o lançamento ${new Date(_data.datetime).toLocaleDateString()} ${new Date(_data.datetime).toLocaleTimeString()}?`,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel:"Sim",
            rejectLabel:"Não",
            accept: () => acceptFunc(),
            reject: () => rejectFunc()
        });
    }, [])


    useEffect(()=>{
        getData();
    }, [currentDate, getData])

    if(!pendences && !dayInfo)
        return 'Carregando'

    return (
        <div className="component-check-time">
            <div className="grid">
                <div className="col-4">
                    <div className="mr-2 mb-2"><strong>Pendências e revições:</strong></div>
                    <Dropdown style={{width:'100%'}} emptyMessage="Não há pendências" options={pendences} value={currentPendence} showClear onChange={(e) => { selectPendence(e.value)}} placeholder="Selecionar datas"/>
                </div>
                <div className="col-4">
                    <div className="mr-2 mb-2"><strong>Data:</strong></div>
                    <Calendar maxDate={new Date()} style={{width:'calc(100% - 90px)'}} id="date" dateFormat="dd/mm/yy" value={currentDate} onChange={(e)=> setCurrentDate(e.value)} locale="pt-br" />
                    <Button onClick={()=> { setCurrentPendence(null); setCurrentDate(new Date()); }} className="ml-2" label="Hoje" />
                </div>
                <div className="col-4">
                    <div className="mr-2 mb-2"><strong>Horário:</strong></div>
                    <Calendar  style={{width:'100%'}} id="time" timeOnly value={currentTime} onChange={(e)=> setCurrentTime(e.value)} locale="pt-br" />
                </div>
                <div className="col-12">
                    <div className="mr-2 mb-2"><strong>Justificativa:</strong></div>
                    <InputTextarea onChange={(e)=> setNote(e.target.value)} style={{width:'100%'}} autoResize />
                </div>
                <div style={{textAlign:'right'}} className="col-12 mt-2 mb-2">
                    <Button loading={isLoad} onClick={saveRegister} label="Registrar"/>
                </div>
            </div>
            <h3>Lançamentos do dia</h3>
            <div className="grid mt-4">
                <div className="col-12">
                    <DataTable value={dayInfo} 
                        emptyMessage="Não há lançamentos"
                    >
                        <Column style={{width:'120px'}} header="Horário" body={(row)=>{
                            const date = new Date(row.datetime)
                            return date.toLocaleTimeString();
                        }} />
                        <Column field="note" header="Descrição" />
                        <Column style={{width:"60px", textAlign:'center'}} header="Revisão" body={(row) => {
                            return ~~row?.review === 1 ? <i className="pi pi-exclamation-triangle"></i>: ''
                        }} />
                        <Column style={{width:'120px'}} header="Ações" body={(row)=> {
                            return <>
                                <Button onClick={()=> deleteCheck(row)} icon="pi pi-trash" className="p-button-sm p-button-danger p-button-text" />
                            </>
                        }} />
                    </DataTable>
                </div>
            </div>
            <Toast position="bottom-center" ref={toast} />
            <ConfirmDialog />
        </div>
    )
}