import { useCallback, useEffect, useState } from 'react';

import { Divider } from 'primereact/divider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { api, URL_REGISTER_BY_DAY, URL_REGISTER, URL_REGISTER_LIST_ERROS, URL_USER_DELETE_CHECK, URL_REGISTER_LIST_REVIEW, URL_REGISTER_UPDATE } from '@/services/provider/api';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog'; 
import { confirmDialog } from 'primereact/confirmdialog'; 
        

import { ProgressSpinner } from 'primereact/progressspinner';
import { useAPP } from '@/services/provider/app';
import { JourneyDay } from '@/components/journeyday';
import { JourneyMonth } from '@/components/journeymonth';
import { JourneyYear } from '@/components/journeyyear';
        

export const ColaboradorPageDash = () => {

    const user = useAPP();

    const params = useParams();
    const [dashdata, setDashdata] = useState({
        listErros: null, 
        listReview: null,
        total: null,
        positive: null,
        negative: null
    })
    
    const [ isLoad, setIsload] = useState(true);
    const [ detailsData, setDetailsData ] = useState(null);
    const [ showAproved, setShowAproved ] = useState(false);
    const [ showReview, setShowReview ] = useState(false);

    const getData = useCallback(async()=> {
        let dataErros = await api.get(`${URL_REGISTER_LIST_ERROS}/${(params.id)}`, null, true);
        let filter = {};
        (dataErros?.data || [])?.map(item => {
            const date = item?.date?.replace('/','');
            if(!filter[date]){
                filter[date] = item;
            }
        });
        dataErros.data = Object.values(filter);
        const dataReview = await api.get(`${URL_REGISTER_LIST_REVIEW}/${(params.id)}`, null, true);
        //console.log(dataReview);

        setDashdata({...dashdata, listErros: dataErros.data, listReview: dataReview.data});

        //setListErros(dataErros.data);
        //setListReview(dataReview.data);
        setIsload(false)

    }, [params])

    const detailsDay = useCallback(async(currentDate) => {
        let data = await api.get(`${URL_REGISTER_BY_DAY}/${currentDate.toISOString().substr(0, 10) }/${(params.id || user.id)}`, null, true)
        data.data = data?.data?.filter(item => ~~item.review === 1 && !item.aproved);
        setDetailsData(data.data);
    },[params, user])

    const detailsDayErros = useCallback(async(currentDate) => {
        let data = await api.get(`${URL_REGISTER_BY_DAY}/${currentDate.toISOString().substr(0, 10) }/${(params.id || user.id)}`, null, true)
        
        //data.data = data?.data?.filter(item => ~~item.review === 1 && !item.aproved);
        setDetailsData(data.data);
    },[params, user])

    const openDetails = (_data) => {
        setDetailsData(null);
        
        const currentDate = _data?.date?.split('/');
        setShowAproved(true);

        detailsDay(new Date(`${currentDate[2]}-${currentDate[1]}-${currentDate[0]} 00:00:00`));
    }

    const openDetailsErros = (_data) => {
        setDetailsData(null);
        
        const currentDate = _data?.date?.split('/');
        setShowReview(true);

        detailsDayErros(new Date(`${currentDate[2]}-${currentDate[1]}-${currentDate[0]} 00:00:00`));
    }

    const updateStatusReview = useCallback(async (_data, _date)=> {
        
        const accept = async()=>{
            setDetailsData(null);
            const sendata = JSON.parse(JSON.stringify(_data));
            delete sendata.id;
            const update = await api.put(`${URL_REGISTER_UPDATE}/${_data.id}`, sendata, true);
            detailsDay(new Date(_date));
            getData();
        }
        
        confirmDialog({
            message: `Deseja atualizar o registro?`,
            header: 'Atenção',
            icon: 'pi pi-info-circle',
            acceptLabel:'Sim',
            rejectLabel:'Não',
            accept: accept,
            reject:()=>{}
        });

    },[])

    useEffect(()=>{
        getData();
    },[getData])


    // if(isLoad)
    //     return (
    //         <div className="colaborador_page-dash">
    //             <div className="grid">
    //                 <div className="col-12" style={{textAlign:'center'}}>
    //                     <ProgressSpinner />
    //                 </div>
    //             </div>
    //         </div>
    //     )

    return (
        <div className="colaborador_page-dash">
            <div className="grid">
                <div className="col-12">
                    <div className="grid">        
                        <div className="col-4">
                            <div className="card" style={{'--bg':'var(--red-100)', '--text':'var(--red-700)'}}>
                                <div className="title">Erros</div>
                                <div className="icon">
                                    <i className="pi pi-arrow-down" />
                                </div>
                                <div className="content">
                                    0
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card" style={{'--bg':'var(--yellow-100)', '--text':'var(--yellow-700)'}}>
                                <div className="title">Pendente Aprovação</div>
                                <div className="icon">
                                    <i className="pi pi-clock" />
                                </div>
                                <div className="content">
                                    0
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card" style={{'--bg':'var(--green-200)', '--text':'var(--green-800)'}}>
                                <div className="title">Banco de horas</div>
                                <div className="icon">
                                    <i className="pi pi-calendar" />
                                </div>
                                <div className="content">
                                    0
                                </div>
                            </div>
                        </div>   
                    </div>
                </div>
                <div className="col-8">
                    <JourneyYear user_id={params.id} />
                    <JourneyDay user_id={params.id} />
                    <JourneyMonth user_id={params.id} />
                </div>
                <div className="col-4">
                    <div>
                        <h2>
                            Marcações para aprovação
                        </h2>
                        <DataTable 
                            value={dashdata.listReview} 
                            emptyMessage="Não há itens para aprovação"
                            size='small'
                            stripedRows
                            rows={5}
                            paginator={dashdata.listReview?.length > 5}
                            loading={!dashdata?.listReview}
                        >
                            <Column field="date" header="Data" />
                            <Column style={{width:'50px', textAlign:'rigth'}} body={(row)=>{
                                return <Button onClick={()=> openDetails(row)} className='p-button-text p-button-sm' icon="pi pi-file" />
                            }} />
                        </DataTable>
                    </div>
                    <div className='mt-4' >
                        <h2>
                            Marcações para revisão
                        </h2>
                        <DataTable 
                            value={dashdata.listErros} 
                            emptyMessage="Não há erros"
                            size='small'
                            stripedRows
                            rows={5}
                            paginator={dashdata.listErros?.length > 5}
                            loading={!dashdata?.listErros}
                        >
                            <Column field="date" header="Data" />
                            <Column style={{width:'100px', textAlign:'center'}} field="Total_Lancamentos" header="Lançamentos" />
                            <Column style={{width:'50px', textAlign:'rigth'}} body={(row)=>{
                                return <Button onClick={() => openDetailsErros(row)} className='p-button-text p-button-sm' icon="pi pi-eye" />
                            }} />
                        </DataTable>
                    </div>
                </div>
            </div>
            <Dialog header="Review " visible={showAproved} style={{ maxWidth: '80%', minWidth:'300px' }} footer={()=>{}} onHide={() => setShowAproved(false)}>
                <DataTable 
                        value={detailsData} 
                        emptyMessage="Não há erros"
                        size='small'
                        stripedRows
                        rows={5}
                        paginator={detailsData?.length > 5}
                        loading={!detailsData}
                    >
                        <Column field="datetime" header="Data" body={(row)=> new Date(row.datetime).toLocaleDateString() } />
                        <Column field="datetime" header="Horário" body={(row)=> new Date(row.datetime).toLocaleTimeString() } />
                        <Column field="note" header="Comentário" />
                        <Column style={{width:'120px', textAlign:'rigth'}} body={(row)=>{
                            return !row.aproved ? 
                                <>
                                    <Button onClick={()=> {updateStatusReview({id: row.id, aproved: 1, date_review: new Date(), user_review: user.user.id }, row.datetime)}} className='p-button-sm p-button-success' icon="pi pi-check" />
                                    <Button onClick={()=> {updateStatusReview({id: row.id, aproved: 0, date_review: new Date(), user_review: user.user.id }, row.datetime)}} className='p-button-sm ml-2 p-button-danger' icon="pi pi-times" />
                                </>
                                :
                                ''
                        }} />
                    </DataTable>
            </Dialog>

            <Dialog header="Registros" visible={showReview} style={{ maxWidth: '80%', minWidth:'300px' }} footer={()=>{}} onHide={() => setShowReview(false)}>
                <DataTable 
                    value={detailsData} 
                    emptyMessage="Não há erros"
                    size='small'
                    stripedRows
                    rows={5}
                    paginator={detailsData?.length > 5}
                    loading={!detailsData}
                >
                    <Column field="datetime" header="Data" body={(row)=> new Date(row.datetime).toLocaleDateString() } />
                    <Column field="datetime" header="Horário" body={(row)=> new Date(row.datetime).toLocaleTimeString() } />
                    <Column field="note" header="Comentário" />
                    <Column style={{width:'120px', textAlign:'rigth'}} body={(row)=>{
                        return !row.aproved ? 
                            <>
                                {/* <Button onClick={()=> {updateStatusReview({id: row.id, aproved: 1, date_review: new Date(), user_review: user.user.id }, row.datetime)}} className='p-button-sm p-button-success' icon="pi pi-check" />
                                <Button onClick={()=> {updateStatusReview({id: row.id, aproved: 0, date_review: new Date(), user_review: user.user.id }, row.datetime)}} className='p-button-sm ml-2 p-button-danger' icon="pi pi-times" /> */}
                            </>
                            :
                            ''
                    }} />
                </DataTable>
            </Dialog>
        </div>
    )
}