import React, {useState, useEffect, useCallback, useRef} from 'react';


import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Rating } from 'primereact/rating';

import { useForm, Controller } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
 

import { calculateDuration } from '@/services/utils';
import { useAPP } from '@/services/provider/app';

import { useNavigate, useParams } from 'react-router-dom';

import { api, URL_FEEDBACK_DETAILS_BY_USER, URL_FEEDBACK_UPDATEANSWER } from '@/services/provider/api';

export const  PageFeedbackViewUser = () => {

    const { user } = useAPP();

    const params = useParams();
    const navigate = useNavigate();

    const [datauser, setDataUser] = useState();
    const [showAvaliable, setShowAvaliable] = useState();

    const currentAnswer = useRef();

    const form = useForm({});
    const control =  form.control;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;


    const printDuration = (_start, _end) =>{
        const duration = calculateDuration(_start, _end);
        let print = '';

        if(duration){
            print = `${duration.days > 0 ? `${duration.days} - ` : '' }${duration.hours}:${duration.minutes}:${duration.seconds}`;
        }

        return print;

    }

    const getContent = () => {

        const _data = currentAnswer.current;

        const types = {
            star:()=>{
                return <div  className={`question ${_data.question_type}`}>
                        <div className="ask">
                            <h3 className="text">
                                {_data?.question_text}
                            </h3>
                        </div>
                        <div className="aswers">
                            <Controller name={`answer_adm`} control={control} render={({ field }) => (
                                <Rating {...field}  onChange={(e)=>{ field.onChange(e.target.value);}} value={field.value}  cancel={false} />
                            )} />
                        </div>
                    </div>
            },
            text:()=>{
                return <div  className={`question ${_data.question_type}`}>
                        <div className="ask">
                            <h3 className="text">
                                {_data?.question_text}
                            </h3>
                        </div>
                        <div className="aswers">
                            <Controller name={`answer_adm`} control={control} render={({ field }) => (
                                <InputTextarea {...field}  inputStyle={{width:'100%'}} style={{width:'100%'}} autoResize {...field}  onChange={(e)=>{field.onChange(e.target.value);}} value={field.value} />
                            )} />
                        </div>
                    </div>
            }
        }

        if(types[_data?.question_type])
            return types[_data?.question_type]();

        return '';

    }

    const getData = useCallback(async()=>{
        const userdata = await api.get(`${URL_FEEDBACK_DETAILS_BY_USER}/${params?.id}/${params?.user}`, null, true);
        setDataUser(userdata.data);
    }, [params, setDataUser])

    const aswerQuestion = useCallback(async (_data)=>{
        currentAnswer.current = _data;
        reset({});
        setShowAvaliable(true);
    }, [reset, setShowAvaliable])

    const onSubmit = useCallback(async (_data) => {
        const now = new Date();
        const data = {..._data, id: currentAnswer.current.id, adm_id: user.id, time_answer_adm: `${now.toISOString().substr(0, 10)} ${now.toLocaleTimeString()}`};
        await api.put(URL_FEEDBACK_UPDATEANSWER, data, true);
        reset({})
        currentAnswer.current = null;
        setShowAvaliable(false);
        getData();
    }, [reset, getData, setShowAvaliable, currentAnswer, user]);
    
    useEffect(()=>{
        getData();
    },[getData])


    return (
        <div className="page-feedback-view-user">
            <BreadCrumb 
                className='mt-2 mb-4'
                model={[
                    { label: 'Feedback', command:()=> navigate(`/user/feedback`) },
                    { label: 'Detalhes feedback', command:()=> navigate(`/user/feedback/${params.id}`) }
                ]} 
                home={{ icon: 'pi pi-home', command:()=> navigate('/user') }}
            />
            <div className="grid">
                <div className="col-12">
                    <strong>Colaborador</strong>: {datauser?.name}
                </div>
                <div className="col-12">
                    <strong>Avaliador</strong>: {datauser?.adm_name || '-'}
                </div>
               <div className="col-12">
                    <div className="grid mt-2">
                        <div className="col-12">
                            <strong>Horário realização</strong>
                        </div>
                        <div className="col-2"></div>
                        <div className="col-4">
                            <strong>Início</strong>
                        </div>
                        <div className="col-4">
                            <strong>Fim</strong>
                        </div>
                        <div className="col-2">
                            <strong>Duração</strong>
                        </div>
                        <div className="col-2">
                            Colaborador
                        </div>
                        <div className="col-4">
                            { datauser?.start_user ? `${(new Date(datauser?.start_user)).toLocaleDateString()} - ${(new Date(datauser?.start_user)).toLocaleTimeString()}` : '-'}
                        </div>
                        <div className="col-4">
                            { datauser?.end_user ? `${(new Date(datauser?.end_user)).toLocaleDateString()} - ${(new Date(datauser?.end_user)).toLocaleTimeString()}` : '-'}
                        </div>
                        <div className="col-2">
                            {printDuration(datauser?.start_user , datauser?.end_user)}
                        </div>
                        <div className="col-2">
                            Avaliador
                        </div>
                        <div className="col-4">
                            { datauser?.start_adm ? `${(new Date(datauser?.start_adm)).toLocaleDateString()} - ${(new Date(datauser?.start_adm)).toLocaleTimeString()}` : '-'}
                        </div>
                        <div className="col-4">
                            { datauser?.end_adm ? `${(new Date(datauser?.end_adm)).toLocaleDateString()} - ${(new Date(datauser?.end_adm)).toLocaleTimeString()}` : '-'}
                        </div>
                        <div className="col-2">
                            {printDuration(datauser?.start_adm , datauser?.end_adm)}
                        </div>
                    </div>
               </div>
            </div>
            <div className="grid mt-4">
                <div className="col-12">
                    <DataTable size="small" stripedRows value={datauser?.answers} emptyMessage="Não há registros" 
                    footerColumnGroup={
                        <ColumnGroup>
                            <Row>
                                <Column footer="Total:" colSpan={2} footerStyle={{textAlign: 'right'}}/>
                                <Column footer={
                                    datauser?.answers.reduce((c, n)=> c + ~~n?.answer_user  , 0)
                                } />
                                <Column footer={
                                    datauser?.answers.reduce((c, n)=> c + ~~n?.answer_adm  , 0)
                                } />
                            </Row>
                        </ColumnGroup>
                    }>
                        <Column style={{width:'30px'}} field="question_order" header="" />
                        <Column field="question_text" header="Pergunta" />
                        <Column field="answer_user" header="Resposta usuário" />
                        <Column body={(row)=>{
                            return !row.answer_adm ? <Button onClick={()=>aswerQuestion(row)} icon="pi pi-pencil" className='p-button-text' /> : row.answer_adm;
                        }} field="answer_adm" header="Resposta avaliador" />
                    </DataTable>
                </div>
            </div>
            <Dialog header="Avaliar" visible={showAvaliable} style={{ width: '90vw' }} footer={null} onHide={() => setShowAvaliable(false)}>
            <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid">
                        <div className="col-12">
                            {/* <Controller name={`answer_adm`} control={control} render={({ field }) => (
                                <InputTextarea {...field} required inputStyle={{width:'100%'}} style={{width:'100%'}} autoResize {...field}  onChange={(e)=>{ field.onChange(e.target.value);}} value={field.value} />
                            )} /> */}
                            {getContent()}
                        </div>
                        <div className="col-12" style={{justifyContent:'flex-end', display:'flex'}}>
                            <Button label='Salvar' />
                        </div>
                    </div>
                </form>
            </Dialog>
        </div>
    )

}