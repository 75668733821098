import React, { useState, useEffect, useCallback, useMemo, useRef} from "react";

import { Divider } from 'primereact/divider';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from "primereact/button";
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
 
 
import { useParams } from "react-router-dom";

import { api, URL_USER_PROFILE, URL_USER_UPDATE } from "@/services/provider/api";

import '@/assets/style/pages/collaborators/view/index.scss';
import { PageHistoryUser } from "@/pages/user/history";
import { CheckTime } from "@/components/checktime";

import { ColaboradorPageDash } from "./dashboard";
import { PageBank } from "@/pages/user/bank";
import { CheckOut } from "@/components/checkout";

export const PageCollaboratorsView = () => {

    
    const params = useParams();

    const [userdata, setUserdata] = useState();
    const [activeIndex, setActiveIndex] = useState(0);
    const [cotnentData, setContentData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit]  = useState(false);
    const [showReset, setShowReset] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [showRegisterOut, setShowRegisterOut] = useState(false);


    // const items = useMemo(()=>[
    //     {label:'Dashboard'},
    //     {label:'Resumo mês atual'},
    //     {label:'Histórico'},
            // {label:'Lançamentos'},
            // {label:'Relatórios'},
            // {label:'Configurações'},
    // ], [])

    const items = useMemo(()=>[
        {label:'Dashboard'},
        {label:'Banco de horas'},
        {label:'Histórico'},
        {label:'Arquivos'}
    ], [])


    const toast = useRef(null);

    const getData = useCallback(async () => {
        setIsLoading(true);
        
        const data = await api.get(`${URL_USER_PROFILE}/${params.id}`, null, true);
        
        setUserdata({...data?.data, redraw: new Date().getTime()});

        setIsLoading(false);

    },[setUserdata, params]);

    const alterActiveUser = useCallback(async () => {

        const accept = async () => {
            
            const result = await api.put(`${URL_USER_UPDATE}/${userdata.id}`, {active: ~~userdata.active === 1 ? 0 : 1} , true);
            
            if(result.data){
                toast.current.show({severity:'success', summary: 'Sucesso', detail: 'Dados atualizados com sucesso', life: 3000});                
            }else{
                toast.current.show({severity:'error', summary: 'Erro', detail:'Houve um erro, tente novamente', life: 3000});
            }

            getData();
        }

        confirmDialog({
            message: ~~userdata.active === 1 ? `Deseja desativar o usuario?` : `Deseja ativar o usuario?`,
            header: 'Confirmar',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel:"Sim",
            rejectLabel:"Não",
            accept: accept,
            reject: () =>{}
        });

    }, [getData, userdata])

    const openContent = useCallback((_data) => {
        let page = <>Pagina não encontrada</>;
        const pageslist = [
            <ColaboradorPageDash />,
            <PageBank user={params.id} />,
            <PageHistoryUser user={params.id} />
        ];

        if(pageslist[_data]){
            page = pageslist[_data];
        }

        setContentData(page);
    },[params])


    const saveData = useCallback(async () => {

        setIsLoading(true);
        setIsEdit(false);
        await getData();
        setIsLoading(false);

    }, [getData])

    useEffect(()=>{
        openContent(activeIndex)
    }, [activeIndex, openContent]);
    

    useEffect(()=>{

        getData();

    }, [getData])
    

    return (
        <div className="page-collaborator-view">
            <Divider type="dashed" >
                <div className="inline-flex align-items-center">
                    <i className="pi pi-user mr-2"></i>
                    <b>Perfil colaborador</b>
                </div>
            </Divider>
            <div className="header">
                <Button onClick={()=>getData()} loading={isLoading} className='p-button-sm p-button-secondary p-button-text' icon="pi pi-sync" tooltip='Sincronizar' tooltipOptions={{position:'bottom'}} />
                <Button onClick={()=> setShowRegister(true)} className='p-button-sm p-button-success p-button-text' icon="pi pi-stopwatch" tooltip='Registro Manual' tooltipOptions={{position:'bottom'}} />
                <Button onClick={()=> setShowRegisterOut(true)} className='p-button-sm p-button-danger p-button-text' icon="pi pi-file" tooltip='Registro Ausência' tooltipOptions={{position:'bottom'}} />
                {/* <Button onClick={()=>setIsEdit(!isEdit)} className='p-button-sm p-button-warning p-button-text' icon={`${ isEdit ? 'pi pi-times' : 'pi pi-pencil'}`} tooltip='Editar' tooltipOptions={{position:'bottom'}}/> */}
                {/* <Button onClick={()=> setShowReset(true) } className='p-button-sm p-button-help p-button-text' icon="pi pi-key" tooltip='Reset de senha' tooltipOptions={{position:'bottom'}}/> */}
                {/* <Button className='p-button-sm p-button-help p-button-text' icon="pi pi-globe" tooltip='Gerar link externo' tooltipOptions={{position:'bottom'}}/> */}
                <Button onClick={alterActiveUser} className='p-button-sm p-button-danger p-button-text' icon={`pi ${ ~~userdata?.active === 1 ? 'pi-eye-slash' : 'pi-eye'}`} tooltip={`${ ~~userdata?.active === 1 ? 'Desativar' : 'Ativar'}`} tooltipOptions={{position:'bottom'}}/>
            </div>
            <div className="grid">
                <div className="col-2">
                    <div className="avatar">
                        <i style={{fontSize:'5em'}} className="pi pi-user" />
                    </div>
                </div>
                <div className="col-10">
                    <form>
                        <div className="grid-form">
                            <div className="title">
                                Matricula: 
                            </div>
                            <div className="text">
                                { !isEdit ?  userdata?.enrol_id : <InputText className="p-inputtext-sm"  />}
                            </div>

                            <div className="title">
                                Nome: 
                            </div>
                            <div className="text">
                                { !isEdit ?  userdata?.name : <InputText className="p-inputtext-sm" />}
                            </div>


                            <div className="title">
                                E-mail: 
                            </div>
                            <div className="text">
                                { !isEdit ?  userdata?.email : <InputText className="p-inputtext-sm" />}
                            </div>

                            <div className="title">
                                Aniversário: 
                            </div>
                            <div className="text">
                                { !isEdit ?   userdata?.birthday?.replace('-', '/') : <Calendar showIcon className="p-inputtext-sm" />}
                            </div>
                            <div className="title">
                                Admissão: 
                            </div>
                            <div className="text">
                            </div>
                            <div className="title">
                                Jornada: 
                            </div>
                            <div className="text">
                                <strong>{userdata?.journey}</strong> - { !isEdit ?  `${userdata?.journey_start} - ${userdata?.lunch_start} - ${userdata?.lunch_end} - ${userdata?.journey_end}` : <Calendar showIcon timeOnly className="p-inputtext-sm" />}
                            </div>

                            <div className="title">
                                Status: 
                            </div>
                            <div className="text">
                                {!isEdit ? (~~userdata?.active === 1 ? 'Ativo' : 'Inativo') : ''}
                            </div>
                        </div>
                    </form>
                </div>
                {
                    isEdit ?
                        <div style={{textAlign:'right', borderTop:'1px solid #ccc'}} className="col-12">
                            <Button onClick={()=> setIsEdit(false)} label="Cancelar" className="p-button-sm mr-2 p-button-danger" /> <Button onClick={saveData} label="Salvar" severity="success" className="p-button-sm p-button-success" />
                        </div>
                    :
                        ''
                }
            </div>
            <div className="grid mt-4">
                <div className="col-12">
                    <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>
                </div>
                <div key={`content-${userdata?.redraw}`} className="col-12">        
                    {cotnentData}
                </div>
            </div>
            <ConfirmDialog />
            <Dialog header="Reset de senha" visible={showReset} style={{ width: '100%', maxWidth:'280px' }} footer={()=>{}} onHide={() => setShowReset(false)}>

            </Dialog>
            <Dialog header="Registrar ponto" visible={showRegister} style={{ maxWidth: '80%', minWidth:'300px' }}  footer={()=>{}} onHide={() => setShowRegister(false)}>
                <CheckTime userId={params.id} />
            </Dialog>
            <Dialog header="Registrar ausência" visible={showRegisterOut} style={{ maxWidth: '80%', minWidth:'300px' }}  footer={()=>{}} onHide={() => setShowRegisterOut(false)}>
                <CheckOut userId={params.id} />
            </Dialog>
            <Toast position="bottom-center" ref={toast} />
        </div>
    )
}