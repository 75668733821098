import React, { useRef, useEffect, useState, useCallback, useMemo} from 'react';
        

import { Divider } from 'primereact/divider';
import { Knob } from 'primereact/knob';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Avatar } from 'primereact/avatar';
import { Dialog } from 'primereact/dialog';

import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from 'primereact/toast';
import { useForm, Controller } from 'react-hook-form';

import { useAPP } from '@/services/provider/app';
import { api, URL_REGISTER_BY_DAY,  URL_FIRST_REGISTER_BY_DAY, URL_LAST_REGISTER_BY_DAY, URL_REGISTER  } from '@/services/provider/api';

import '@/assets/style/component/journeyday/index.scss'
import { secondsToTime, timeToSeconds } from '@/services/utils';

export const JourneyDay = (props) => {


    const { user } = useAPP();

    const [dataDay, setDataDay] = useState({
        start: null,
        startLunch: user?.lunch_start,
        endLunch: user?.lunch_end,
        end: null,
        total: null,
        note: null
    })

    const bar = useRef();
    const timer = useRef();

    const barDay = useRef({
        normal: 0,
        pendence: 0,
        extra: 0
    })

    const getData = useCallback(async () => {
        const data = await api.get(`${URL_REGISTER_BY_DAY}/${new Date().toISOString().substring(0, 10)}/${props?.user_id || user.id}`, null, true)
        
        //Test
        //const data = await api.get(`${URL_REGISTER_BY_DAY}/2024-11-01/4`, null, true)


        let start = null;
        let end = null;

        if(data.data.length > 1){
            start = new Date(data.data.at(0).datetime).toLocaleTimeString();
            end = new Date(data.data.at(-1).datetime).toLocaleTimeString();
        }else if(data.data.length === 1){
            start = new Date(data.data.at(0).datetime).toLocaleTimeString();
        }

        setDataDay({...dataDay, start: start, end: end});

    }, [])

    useEffect(()=> {
        getData();
    }, [getData])


    useEffect(()=>{

        timer.current = setInterval(()=> {
           // console.log(user)
           let _s = timeToSeconds(dataDay.start);
           let _lunch = timeToSeconds(user.lunch_end) - timeToSeconds(user.lunch_start);
           let _day = timeToSeconds(user.journey_end) - timeToSeconds(user.journey_start);
           let _totalDay = _day - _lunch;
           let _e = _s + _totalDay + _lunch;
           let note = '';
           let _total = null; 
           let _totalCalc = null;
            
            if(dataDay.start && dataDay.end){
                _totalCalc = timeToSeconds(dataDay.end) - timeToSeconds(dataDay.start) - _lunch;
                _total = secondsToTime(_totalCalc)
            }else if(dataDay.start){
                note = `Previa saída: ${secondsToTime(_e)}`
                const recal = timeToSeconds(new Date().toLocaleTimeString());
                if(recal <= _totalDay){
                    barDay.current.pendence = _totalDay * 100 / recal;
                    barDay.current.normal = 100 - barDay.current.pendence;
                }else{
                    barDay.current.extra =  _totalDay * 100 / recal;
                    barDay.current.normal = 100 - barDay.current.extra;
                }

            }
            
            if(_totalCalc){
                if(_totalCalc > _totalDay){
                    const recal = _totalCalc - _totalDay;
                    note = `Extra: ${secondsToTime(recal)}`;
                    barDay.current.extra = recal * 100 / _totalCalc;
                    barDay.current.normal = 100 - barDay.current.extra;
                }else if(_totalCalc < _totalDay){
                    const recal = _totalDay - _totalCalc;
                    note = `Pendência: ${secondsToTime(recal)}`;
                    barDay.current.pendence = recal * 100 / _totalDay;
                    barDay.current.normal = 100 - barDay.current.pendence;
                }else{
                    barDay.current.normal = 100;
                }
                
            }
            
            setDataDay({...dataDay, note: note, total: _total})

        }, 1000);

        return ( ()=> {
            clearInterval(timer.current);
        })

    }, [dataDay])

    return (
        <div className="component-journey-day">
            <h2>
                Resumo dia
            </h2>
            <div>
                <div ref={bar} style={{gridTemplateColumns: `${barDay.current.normal}% ${barDay.current.pendence}% ${barDay.current.extra}%`}} className="bar-journey">
                    <div className="normal"></div>
                    <div className="pendence"></div>
                    <div className="extra"></div>
                </div>
            </div>
            <div className="grid mt-3">
                <DataTable 
                    style={{width:'98%', margin:'0 auto'}}
                    size='small'
                    value={[dataDay]}
                >
                    <Column style={{width:'100px'}} field="start" header="Entrada" />
                    <Column style={{width:'100px'}} field='startLunch' header="Almoço início" />
                    <Column style={{width:'100px'}} field='endLunch' header="Almoço fim" />
                    <Column style={{width:'100px'}} field='end' header="Saída" />
                    <Column style={{width:'100px'}} field='total' header="Total" />
                    <Column style={{textAlign:'center'}} field='note' header="" />
                </DataTable>
            </div>
        </div>
    )

}

